import React from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import BriefProposalReviewForm from 'components/BriefProposalReviewForm/BriefProposalReviewForm';

import { routes } from 'options/routes';

import { postBuyerDesignerReview } from 'api';

const BuyerReviewDesigner = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { brief_uuid, brief_designer_uuid } = useParams();
  const [briefData, setMessage] = useOutletContext();
  const designer = briefData?.designers[0];

  const onSubmit = async (d) => {
    try {
      const res = await postBuyerDesignerReview(brief_uuid, brief_designer_uuid, {
        rating: d.rating,
        details: d.review,
      });

      if (!res.success) {
        throw new Error('There was an error submtting review');
      }
      queryClient.refetchQueries(['buyerSingleBrief', brief_uuid, brief_designer_uuid], {
        type: 'active',
        exact: true,
      });
      navigate(`${routes.briefsDashboard}/${brief_uuid}/${brief_designer_uuid}/brief`);
    } catch (err) {
      console.log('err');
      setMessage('There was an error submitting review.');
    }
  };

  return (
    <Box mt={2}>
      <BriefProposalReviewForm designer={designer} onSubmit={onSubmit} />
    </Box>
  );
};

BuyerReviewDesigner.propTypes = {};

export default BuyerReviewDesigner;
