import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useOutletContext, useParams } from 'react-router';

import { Box } from '@mui/material';
import BriefProposalReviewForm from 'components/BriefProposalReviewForm/BriefProposalReviewForm';
import { postBuyerOpenCallsSingleReview } from 'api';
import { routes } from 'options/routes';

const BuyerOpenCallsReview = (props) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { brief_uuid, brief_designer_uuid } = useParams();
  const [briefData, setMessage] = useOutletContext();
  const designer = briefData?.designers[0];

  useEffect(() => {
    queryClient.refetchQueries(['buyerSingleOpenCall', brief_uuid, brief_designer_uuid], {
      type: 'active',
      exact: true,
    });
  }, [brief_designer_uuid, brief_uuid, queryClient]);

  const onSubmit = async (d) => {
    try {
      const res = await postBuyerOpenCallsSingleReview(brief_uuid, brief_designer_uuid, {
        rating: d.rating,
        details: d.review,
      });

      if (!res.success) {
        throw new Error('There was an error submtting review');
      }
      queryClient.refetchQueries(['buyerSingleOpenCall', brief_uuid, brief_designer_uuid], {
        type: 'active',
        exact: true,
      });
      navigate(`${routes.openCallsDashboard}/${brief_uuid}/${brief_designer_uuid}/open-call`);
    } catch (err) {
      setMessage(err.toString());
    }
  };

  return (
    <Box mt={2}>
      <BriefProposalReviewForm designer={designer} onSubmit={onSubmit} />
    </Box>
  );
};

BuyerOpenCallsReview.propTypes = {};

export default BuyerOpenCallsReview;
