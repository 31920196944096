import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import { useQuery, useQueryClient } from 'react-query';

import { Box } from '@mui/material';
import ModalConfirmAccept from 'components/ModalConfirmAccept/ModalConfirmAccept';
import Spinner from 'components/Spinner/Spinner';
import ModalProposalAgreementSigned from 'components/ModalProposalAgreementSigned/ModalProposalAgreementSigned';
import AgreementAccordion from 'components/AgreementAccordion/AgreementAccordion';
import ModalAgreementReview from 'components/ModalAgreeementReview/ModalAgreementReview';

import { routes } from 'options/routes';
import { AGREEMENT_STATUS } from 'options/agreementStatus';

import { openHelloSign } from 'utils/helloSign';
import { parseDateToFullDateString } from 'utils/parseDates';
import { parseRemoteNegotiatedTerms } from 'pages/Dashboard/Designer/Briefs/DesignerBriefAgreement/DesignerBriefAgreement.utils';
import { parseRemoteData as parseRemoteTermsData } from 'pages/Dashboard/Buyer/Terms/BuyerTermsNew/utils/BuyerTermsNew.utils';
import { getIsAgreementTabDisabled } from '../BuyerOpenCallsSingle.utils';

import {
  acceptBuyerOpenCallsSingleAgreement,
  editBuyerOpenCallsSingleAgreement,
  getBuyerOpenCallsSignUrl,
  getBuyerOpenCallsSingleAgreement,
  getSingleTerm,
} from 'api';

const BuyerOpenCallsAgreementTab = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { brief_uuid, brief_designer_uuid } = useParams();
  const [briefData, setMessage, userType] = useOutletContext();

  const { project_status, agreement_status, proposal_status, currency, agreement_type, selected_terms_uuid, status } =
    briefData;

  useEffect(() => {
    const isAgreementDisabled = getIsAgreementTabDisabled(project_status, proposal_status, status);
    if (isAgreementDisabled) {
      navigate(`${routes.openCallsDashboard}/${brief_uuid}/${brief_designer_uuid}/open-call`);
    }
  }, [navigate, project_status, proposal_status, brief_uuid, brief_designer_uuid, status]);

  const { data, isLoading, refetch, isRefetching } = useQuery(
    ['buyerOpenCallAgreement', brief_uuid, brief_designer_uuid],
    () => getBuyerOpenCallsSingleAgreement(brief_uuid, brief_designer_uuid),
    {
      enabled: true,
      staleTime: 5 * 60 * 1000,
    }
  );

  const nonParsedValuesAgreement = data?.agreement || {};
  const parsedAgreementData =
    (data && !data?.agreement?.download_url && parseRemoteNegotiatedTerms(data?.agreement)) || {};
  const download_url = data?.agreement?.download_url;
  const agreementError = !data?.data?.success && data?.data?.error_message;

  const { data: termsData } = useQuery(
    ['buyerOpenCallSingleTerm', selected_terms_uuid],
    () => (selected_terms_uuid ? getSingleTerm(selected_terms_uuid) : {}),
    {
      enabled: true,
      staleTime: 2 * 60 * 1000,
    }
  );
  const parsedTermsData = (termsData?.terms && parseRemoteTermsData(termsData.terms)) || {};

  const { data: helloSignData = {}, refetch: refetchSignUrl } = useQuery(
    ['buyerOpenCallHelloSign', brief_uuid, brief_designer_uuid],
    () => getBuyerOpenCallsSignUrl(brief_uuid, brief_designer_uuid),
    {
      enabled: agreement_status === AGREEMENT_STATUS.AWAITING_SIGN,
    }
  );

  const { claim_url, success: helloSignSuccess, message: helloSignMessage } = helloSignData;
  const helloSignErrorMsg = !helloSignSuccess && helloSignData?.data?.error_message;

  useEffect(() => {
    const errorMsg = agreementError || helloSignErrorMsg;
    if (errorMsg) {
      setMessage(errorMsg);
    }
  }, [helloSignErrorMsg, agreementError, setMessage]);

  const [openAgreementReview, setOpenAgreementReview] = useState(false);
  const [openAcceptAgreement, setOpenAcceptAgreement] = useState(false);
  const [openHasAcceptedAgreement, setOpenHasAcceptedAgreement] = useState(false);

  const agreement_uuid = parsedAgreementData?.agreement_uuid;

  const handleAcceptAgreement = async () => {
    const res = await acceptBuyerOpenCallsSingleAgreement(brief_uuid, brief_designer_uuid, agreement_uuid);
    if (!res.success) {
      setMessage('There was a problem accepting the agreement.');
      return setOpenAcceptAgreement(false);
    }
    setOpenAcceptAgreement(false);
    setOpenHasAcceptedAgreement(true);
    // refetch and refresh data
    refetch();
    queryClient.refetchQueries(['buyerSingleOpenCall', brief_uuid, brief_designer_uuid], {
      type: 'active',
      exact: true,
    });
  };

  const handleSubmitAgreement = async (data) => {
    const { negotiated_upfront_payment, negotiated_deadline, ...rest } = data;
    const parsedForm = {
      ...rest,
      negotiated_upfront_payment: negotiated_upfront_payment === 'yes',
      negotiated_deadline: negotiated_deadline ? parseDateToFullDateString(new Date(negotiated_deadline)) : '',
    };

    // submit new negotiated terms
    const res = await editBuyerOpenCallsSingleAgreement(brief_uuid, brief_designer_uuid, parsedForm);
    if (!res.success) {
      setOpenAgreementReview(false);
      return setMessage('There was an error updating agreement');
    }
    setOpenAgreementReview(false);
    setMessage('You have successfully editted the agreement');
    // setting data to be the agreement response
    queryClient.setQueryData(['buyerOpenCallAgreement', brief_uuid, brief_designer_uuid], () => ({
      agreement: res?.agreement,
    }));
  };

  const handleEditAgreement = useCallback(() => {
    setOpenAgreementReview(true);
  }, []);

  const onAcceptAgreement = () => {
    setOpenAcceptAgreement(true);
  };

  const onHelloSignClick = useCallback(async () => {
    openHelloSign(claim_url, () => {
      refetchSignUrl();
      refetch();
      queryClient.refetchQueries(['buyerSingleOpenCall', brief_uuid], { type: 'active', exact: true });
    });
  }, [brief_uuid, claim_url, queryClient, refetch, refetchSignUrl]);

  const loading = isLoading || isRefetching;

  return (
    <Box mt={2}>
      {loading ? (
        <Box mt={2}>
          <Spinner align="center" />
        </Box>
      ) : (
        <>
          <AgreementAccordion
            briefDetails={{
              ...([
                AGREEMENT_STATUS.IN_REVIEW,
                AGREEMENT_STATUS.AWAITING_BUYER,
                AGREEMENT_STATUS.AWAITING_SIGN,
              ].includes(agreement_status)
                ? parsedAgreementData
                : {}),
              ...parsedTermsData,
              currency,
            }}
            agreement_status={agreement_status}
            project_status={project_status}
            userType={userType}
            download_url={download_url}
            onAcceptAgreement={onAcceptAgreement}
            onHelloSignClick={claim_url && onHelloSignClick}
            helloSignMessage={helloSignMessage}
            handleEditAgreement={handleEditAgreement}
          />
          {openAgreementReview && (
            <ModalAgreementReview
              open={openAgreementReview}
              variant="briefs"
              handleClose={() => setOpenAgreementReview(false)}
              handleSubmit={handleSubmitAgreement}
              editValues={nonParsedValuesAgreement}
              agreement_type={agreement_type}
              currency={currency}
            />
          )}{' '}
          {openAcceptAgreement && (
            <ModalConfirmAccept
              open={openAcceptAgreement}
              name="agreement"
              handleSubmit={handleAcceptAgreement}
              handleClose={() => setOpenAcceptAgreement(false)}
            />
          )}
          {openHasAcceptedAgreement && (
            <ModalProposalAgreementSigned
              open={openHasAcceptedAgreement}
              handleClose={() => setOpenHasAcceptedAgreement(false)}
              userType={userType}
            />
          )}
        </>
      )}
    </Box>
  );
};

BuyerOpenCallsAgreementTab.propTypes = {};

export default BuyerOpenCallsAgreementTab;
