import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/system';
// import CustomCheckBoxTerms from 'components/Inputs/CustomCheckBoxTerms/CustomCheckBoxTerms';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import BodyText from 'components/Typography/BodyText/BodyText';
import Spinner from 'components/Spinner/Spinner';
import AgreementFile from 'components/AgreementFile/AgreementFile';

import { userTypes } from 'options/userTypes';
import { AGREEMENT_STATUS } from 'options/agreementStatus';
import { PROJECT_STATUS } from 'options/projectStatus';
import { CUSTOM_PROP_TYPES } from 'options/customPropTypes';

import { isAnyBuyer } from 'utils/isAnyBuyer';
import { PROPOSAL_STATUS } from 'options/proposalStatus';

const BriefProposalAgreementForm = ({
  download_url,
  userType,
  agreement_status,
  project_status,
  onAcceptAgreement,
  onHelloSignClick,
  helloSignMessage,
  variant,
  proposal_status,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleAccept = useCallback(async () => {
    setIsLoading(true);
    await onAcceptAgreement();
    setIsLoading(false);
  }, [onAcceptAgreement]);

  const DesignersAgreementInReviewProposaStatusMessaging = (proposal_status) => {
    switch (proposal_status) {
      case PROPOSAL_STATUS.DRAFT:
      case '':
        return (
          <>
            <BodyText text="Please proceed to make a proposal for the brief. Once your proposal, has been accepted, you will be able to negotiate and sign the agreement." />
            <BodyText text="If your proposal is declined, please feel free to complete the upload form and publish it to your profile." />
          </>
        );
      case PROPOSAL_STATUS.PENDING_APPROVAL:
        return (
          <>
            <BodyText text="Waiting for Buyer to respond to proposal." />
            <BodyText text="Once your proposal, has been accepted, you will be able to accept and sign the agreement." />
            <BodyText text="If your proposal is declined, please feel free to complete the upload form and publish it to your profile." />
          </>
        );
      case PROPOSAL_STATUS.APPROVED:
        return (
          <>
            <BodyText text="Congratulations, your proposal has been accepted. " />
            <BodyText text="Please accept and sign or negotiate the agreement." />
          </>
        );
      default:
        return <></>;
    }
  };

  if (project_status === PROJECT_STATUS.PENDING && variant !== 'pitch') {
    if (userType !== userTypes.designer) {
      return (
        <Box mb={2}>
          <BodyText text="Waiting for the designer to respond to the brief." />
        </Box>
      );
    }
    return (
      <Box mb={2}>
        <BodyText text="Please review the brief and the terms and conditions." />
        <BodyText text="By creating a proposal, you are accepting to proceed with the brief; this does not mean you accept the terms and conditions. These are here, so you have transparency on the proposed agreement from the buyer." />
        <BodyText text="The agreement can be negotiated and signed later." />
        <BodyText text="Please begin your discussions and development with the buyer using chat/messaging." />
      </Box>
    );
  }

  if (project_status === PROJECT_STATUS.REJECTED) {
    return (
      <Box mb={2}>
        <BodyText text="The designer has rejected the brief. No further action is needed." />
      </Box>
    );
  }

  if (userType === userTypes.designer && agreement_status === AGREEMENT_STATUS.DRAFT) {
    return (
      <Box mb={2}>
        <BodyText text="Waiting for the buyer to submit terms and conditions." />
      </Box>
    );
  }

  // VARIANT 1
  if (
    (userType === userTypes.designer &&
      agreement_status === AGREEMENT_STATUS.IN_REVIEW &&
      project_status === PROJECT_STATUS.ACCEPTED) ||
    (agreement_status === AGREEMENT_STATUS.AWAITING_BUYER && isAnyBuyer(userType))
  ) {
    return isLoading ? (
      <Box my={2}>
        <Spinner align="center" />
      </Box>
    ) : (
      <Box mb={2}>
        <CustomButton text="Proceed with agreement" onClick={handleAccept} variant="outlined" />
        <Box my={2}>
          {isAnyBuyer(userType) ? (
            <>
              <BodyText text="Congratulations, the designer has accepted the agreement." />
              <BodyText text="Please accept and sign or negotiate the agreement." />
            </>
          ) : (
            DesignersAgreementInReviewProposaStatusMessaging(proposal_status)
          )}
        </Box>
      </Box>
    );
  }

  // VARIANT 2
  if (agreement_status === AGREEMENT_STATUS.IN_REVIEW && isAnyBuyer(userType)) {
    return (
      <Box mb={2}>
        <BodyText text="Waiting for the designer to accept the Agreement." />
      </Box>
    );
  }

  // VARIANT 3
  if (agreement_status === AGREEMENT_STATUS.AWAITING_BUYER && userType === userTypes.designer) {
    return (
      <Box mb={2}>
        <BodyText text="Waiting for the buyer to accept the Agreement." />
      </Box>
    );
  }

  // VARIANT 4
  if (agreement_status === AGREEMENT_STATUS.AWAITING_SIGN) {
    if (onHelloSignClick) {
      return (
        <Box mb={2}>
          <BodyText text="Congratulations, you have accepted the Agreement. Please sign the agreement." />
          <Box my={2}>
            <CustomButton text="Sign Agreement" onClick={onHelloSignClick} variant="outlined" />
          </Box>
        </Box>
      );
    } else if (helloSignMessage) {
      return (
        <Box mb={2}>
          <BodyText text={helloSignMessage} />
        </Box>
      );
    } else {
      return (
        <Box mb={2}>
          <BodyText text="Congratulations, you have accepted the Agreement. Please check your email for further instructions on how to sign the agreement." />
        </Box>
      );
    }
  }

  // VARIANT 5
  if (
    agreement_status === AGREEMENT_STATUS.AGREED ||
    agreement_status === AGREEMENT_STATUS.UPFRONT_PAID ||
    agreement_status === AGREEMENT_STATUS.PAID
  ) {
    return (
      <Box>
        <Box my={2}>
          <BodyText text="The agreement has now been signed and completed. Please click the file below to download it:" />
        </Box>
        <AgreementFile download_url={download_url} />
      </Box>
    );
  }

  return null;
};

BriefProposalAgreementForm.propTypes = {
  file: PropTypes.shape({
    date_and_time_of_file: PropTypes.arrayOf(PropTypes.object),
  }),
  userType: CUSTOM_PROP_TYPES.USER_TYPE,
  agreement_status: PropTypes.oneOf([...Object.values(AGREEMENT_STATUS)]),
};

export default BriefProposalAgreementForm;
