import React, { useCallback, useState, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import AgreementAccordion from 'components/AgreementAccordion/AgreementAccordion';
import Spinner from 'components/Spinner/Spinner';
import ModalCongratulations from 'components/ModalCongratulations/ModalCongratulations';
import ModalConfirmAccept from 'components/ModalConfirmAccept/ModalConfirmAccept';
import ModalProposalAgreementSigned from 'components/ModalProposalAgreementSigned/ModalProposalAgreementSigned';
import ModalBuyerTermsForm from 'components/ModalBuyerTermsForm/ModalBuyerTermsForm';
import EmptyListCreateNew from 'components/EmptyListCreateNew/EmptyListCreateNew';
import ModalAgreementReview from 'components/ModalAgreeementReview/ModalAgreementReview';

import { routes } from 'options/routes';
import { LICENSE_TYPES } from 'options/briefOptions';
import { AGREEMENT_STATUS } from 'options/agreementStatus';
import { CURRENCY_MAP } from 'options/currency';

import { openHelloSign } from 'utils/helloSign';
import { getErrorMessage } from 'utils/getErrorMessage';
import { getIsAgreementTabDisabled } from 'pages/Dashboard/Designer/DesignerPitches/DesignerPitchesSingle/DesignerPitchesSingle.utils';
import { parseRemoteNegotiatedTerms } from 'pages/Dashboard/Designer/Briefs/DesignerBriefAgreement/DesignerBriefAgreement.utils';
import { parseRemoteData as parseRemoteTermsData } from 'pages/Dashboard/Buyer/Terms/BuyerTermsNew/utils/BuyerTermsNew.utils';

import {
  getSingleTerm,
  createTerms,
  createBuyersPitchAgreement,
  getBuyersPitchAgreement,
  postBuyersPitchAgreementAccept,
  updateBuyersPitchAgreement,
  getBuyerPitchSignUrl,
} from 'api';

const BuyerPitchesSingleAgreementTab = ({ isStory }) => {
  const queryClient = useQueryClient();
  const { pitch_uuid } = useParams();
  const [pitch, setMessage, userType] = useOutletContext();

  const navigate = useNavigate();

  const { project_status, agreement_status, selected_agreement_type, currency, price } = pitch;

  const [openTermsModal, setOpenTermsModal] = useState(false);
  const [openCongratulations, setOpenCongratulations] = useState(false);
  const [openAgreementReview, setOpenAgreementReview] = useState(false);
  const [openAcceptAgreement, setOpenAcceptAgreement] = useState(false);
  const [openHasAcceptedAgreement, setOpenHasAcceptedAgreement] = useState(false);

  useEffect(() => {
    const isAgreementTabDisabled = getIsAgreementTabDisabled(project_status);

    if (isAgreementTabDisabled) {
      navigate(`${routes.pitchesDashboard}/${pitch_uuid}}/pitch`);
    }
  }, [pitch_uuid, navigate, project_status]);

  const {
    data: agreementData,
    isLoading: agreementLoading,
    refetch: agreementRefetch,
    isRefetching: isAgreementRefetching,
  } = useQuery(['buyerPitchAgreement', pitch_uuid], () => getBuyersPitchAgreement(pitch_uuid), {
    enabled: true,
  });

  const nonParsedValuesAgreement = agreementData?.agreement || {};
  const download_url = agreementData?.agreement?.download_url;
  const agreementError =
    !agreementData?.data?.success && agreementData?.data?.error_message && agreementData?.status !== 400;

  // use parsed negotiated terms in brief summary component
  const parsedNegotiatedAgreement =
    (agreementData &&
      !agreementData?.agreement?.download_url &&
      parseRemoteNegotiatedTerms(nonParsedValuesAgreement)) ||
    {};

  const { data: helloSignData = {}, refetch: refetchSignUrl } = useQuery(
    ['buyerPitchHelloSign', pitch_uuid],
    () => getBuyerPitchSignUrl(pitch_uuid),
    {
      enabled: agreement_status === AGREEMENT_STATUS.AWAITING_SIGN,
    }
  );

  const { claim_url, success: helloSignSuccess, message: helloSignMessage } = helloSignData;
  const helloSignErrorMsg = !helloSignSuccess && helloSignData?.data?.error_message;

  useEffect(() => {
    const errorMsg = agreementError || helloSignErrorMsg;
    if (errorMsg) {
      setMessage(errorMsg);
    }
  }, [helloSignErrorMsg, agreementError, setMessage]);

  const { selected_terms_uuid } = nonParsedValuesAgreement;

  const {
    data: termsData,
    isLoading: isTermsLoading,
    refetch: termsRefetch,
    isRefetching: isTermsRefetching,
  } = useQuery(
    ['buyerPitchTerms', selected_terms_uuid],
    () => (selected_terms_uuid ? getSingleTerm(selected_terms_uuid) : {}),
    {
      enabled: true,
    }
  );

  const buyerTerms = (termsData?.terms && parseRemoteTermsData(termsData?.terms)) || {};

  const handleAcceptAgreement = useCallback(async () => {
    const res = await postBuyersPitchAgreementAccept(pitch_uuid);
    if (!res.success) {
      return setMessage('There was an error while accepting the agreement. Please try again.');
    }
    // refetch and refresh data
    agreementRefetch();
    queryClient.refetchQueries(['buyerSinglePitch', pitch_uuid], { type: 'active', exact: true });
    setOpenHasAcceptedAgreement(true);
  }, [agreementRefetch, pitch_uuid, queryClient, setMessage]);

  const handleSubmitTerms = useCallback(
    async (data) => {
      const { selected_terms_uuid, agreement_file, agreement_title, agreement_body } = data;
      const {
        negotiated_payment_terms,
        negotiated_royalty_percentage,
        negotiated_royalty_review_period,
        negotiated_one_off_budget,
      } = data;

      const termsData = { file: agreement_file[0], title: agreement_title, body: agreement_body };
      let new_selected_terms_uuid;

      if (!selected_terms_uuid) {
        const resTerms = await createTerms(termsData);

        if (!resTerms.success) {
          throw new Error(getErrorMessage(resTerms));
        }

        const { terms_uuid } = resTerms.terms;
        new_selected_terms_uuid = terms_uuid;
      }

      //  BUYER CREATE AGREEMENT ENDPOINT
      const resAgreement = await createBuyersPitchAgreement(pitch_uuid, {
        ...(selected_agreement_type === LICENSE_TYPES.royalty
          ? { negotiated_royalty_percentage, negotiated_royalty_review_period }
          : { negotiated_one_off_budget }),
        negotiated_payment_terms,
        selected_terms_uuid: new_selected_terms_uuid ? new_selected_terms_uuid : selected_terms_uuid,
      });

      if (!resAgreement.success) {
        return setMessage('There was an error while submitting the agreement. Please try again.');
      }
      // refetch and refresh data
      termsRefetch();
      agreementRefetch();
      queryClient.refetchQueries(['buyerSinglePitch', pitch_uuid], { type: 'active', exact: true });

      setOpenTermsModal(false);
      openCongratulations(true);
    },
    [agreementRefetch, openCongratulations, pitch_uuid, queryClient, selected_agreement_type, setMessage, termsRefetch]
  );

  const handleSubmitAgreement = useCallback(
    async (data) => {
      // submit new negotiated terms
      const res = await updateBuyersPitchAgreement(pitch_uuid, data); // BUYER EDIT AGREEMNET ENDPOINT
      if (!res.success) {
        setOpenAgreementReview(false);
        return setMessage('There was an error updating agreement');
      }
      setOpenAgreementReview(false);
      setMessage('You have successfully editted the agreement');
      // setting data to be the agreement response
      queryClient.setQueryData(['buyerPitchAgreement', pitch_uuid], () => ({
        agreement: res?.agreement,
      }));
    },
    [pitch_uuid, queryClient, setMessage]
  );

  const handleEditAgreement = useCallback(() => {
    setOpenAgreementReview(true);
  }, []);

  const onHelloSignClick = useCallback(async () => {
    openHelloSign(claim_url, () => {
      refetchSignUrl();
      agreementRefetch();
      queryClient.refetchQueries(['buyerSinglePitch', pitch_uuid], { type: 'active', exact: true });
    });
  }, [agreementRefetch, claim_url, pitch_uuid, queryClient, refetchSignUrl]);

  const loading = isTermsLoading || isTermsRefetching || agreementLoading || isAgreementRefetching;
  const selectedCurrency = CURRENCY_MAP[currency] || '£';

  return (
    <Box mt={2}>
      {loading ? (
        <Box>
          <Spinner align="center" />
        </Box>
      ) : (
        <AgreementAccordion
          briefDetails={{
            ...([AGREEMENT_STATUS.IN_REVIEW, AGREEMENT_STATUS.AWAITING_BUYER, AGREEMENT_STATUS.AWAITING_SIGN].includes(
              agreement_status
            )
              ? parsedNegotiatedAgreement
              : {}),
            ...buyerTerms,
            currency,
          }}
          agreement_status={agreement_status}
          project_status={project_status}
          download_url={download_url}
          userType={userType}
          file={{}}
          onAcceptAgreement={() => setOpenAcceptAgreement(true)}
          onHelloSignClick={claim_url && onHelloSignClick}
          helloSignMessage={helloSignMessage}
          handleEditAgreement={handleEditAgreement}
          variant="pitch"
        />
      )}
      {!loading && !Object.keys({ ...buyerTerms }).length && (
        <EmptyListCreateNew
          title=""
          description="There are no terms for this pitch yet."
          btnText="Submit Terms"
          onClick={() => setOpenTermsModal(true)}
        />
      )}
      {openCongratulations && (
        <ModalCongratulations
          handleClose={() => setOpenCongratulations(false)}
          action={() => {}}
          text="You have submitted the terms!"
          title="Congratulations"
          open={openCongratulations}
        />
      )}
      {openAcceptAgreement && (
        <ModalConfirmAccept
          open={openAcceptAgreement}
          name="agreement"
          handleSubmit={handleAcceptAgreement}
          handleClose={() => setOpenAcceptAgreement(false)}
        />
      )}
      {openHasAcceptedAgreement && (
        <ModalProposalAgreementSigned
          open={openHasAcceptedAgreement}
          handleClose={() => setOpenHasAcceptedAgreement(false)}
          userType={userType}
        />
      )}
      {openAgreementReview && (
        <ModalAgreementReview
          open={openAgreementReview}
          variant="negotiation"
          handleClose={() => setOpenAgreementReview(false)}
          handleSubmit={handleSubmitAgreement}
          editValues={nonParsedValuesAgreement}
          agreement_type={selected_agreement_type}
          currency={currency}
        />
      )}{' '}
      {openTermsModal && (
        <ModalBuyerTermsForm
          open={openTermsModal}
          handleClose={() => setOpenTermsModal(false)}
          onSubmit={handleSubmitTerms}
          userType={userType}
          agreement_type={selected_agreement_type}
          price={price}
          currency={currency}
        />
      )}
    </Box>
  );
};

BuyerPitchesSingleAgreementTab.propTypes = {};

export default BuyerPitchesSingleAgreementTab;
