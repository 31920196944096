import React, { useCallback, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useOutletContext, useParams } from 'react-router';

import { Box } from '@mui/material';

import AgreementAccordion from 'components/AgreementAccordion/AgreementAccordion';
import Spinner from 'components/Spinner/Spinner';
import ModalConfirmAccept from 'components/ModalConfirmAccept/ModalConfirmAccept';
import ModalProposalAgreementSigned from 'components/ModalProposalAgreementSigned/ModalProposalAgreementSigned';

import { AGREEMENT_STATUS } from 'options/agreementStatus';
import { userTypes } from 'options/userTypes';
import { routes } from 'options/routes';

import { openHelloSign } from 'utils/helloSign';
import { parseRemoteData as parseRemoteTermsData } from 'pages/Dashboard/Buyer/Terms/BuyerTermsNew/utils/BuyerTermsNew.utils';
import { parseRemoteNegotiatedTerms } from 'pages/Dashboard/Designer/Briefs/DesignerBriefAgreement/DesignerBriefAgreement.utils';
import { getIsAgreementTabDisabled } from '../DesignerPitchesSingle.utils';

import {
  acceptDesignerPitchBuyerSingleAgreement,
  getDesignerPitchBuyerSingleAgreement,
  getDesignerPitchSignUrl,
  getSingleTerm,
} from 'api';

const DesignerPitchesSingleAgreementTab = ({ isStory }) => {
  const queryClient = useQueryClient();
  const { pitch_uuid, buyer_pitch_uuid } = useParams();
  const [pitch, setMessage, userType] = useOutletContext();
  const navigate = useNavigate();

  const { project_status, agreement_status, currency } = pitch;

  useEffect(() => {
    const isAgreementDisabled = getIsAgreementTabDisabled(agreement_status);

    if (isAgreementDisabled) {
      navigate(`${routes.pitchesDashboard}/${pitch_uuid}/pitch`);
    }
  }, [pitch_uuid, navigate, agreement_status]);

  const { data, isLoading, refetch } = useQuery(
    ['designerPitchAgreement', pitch_uuid, buyer_pitch_uuid],
    () => getDesignerPitchBuyerSingleAgreement(pitch_uuid, buyer_pitch_uuid), // ADD REAL ENDPOINT
    {
      enabled: true,
      staleTime: 2 * 60 * 1000,
      refetchOnMount: 'always',
    }
  );

  const [openAcceptAgreement, setOpenAcceptAgreement] = useState(false);
  const [openHasAcceptedAgreement, setOpenHasAcceptedAgreement] = useState(false);
  const nonParsedValues = data?.agreement || {};
  const download_url = data?.agreement?.download_url;
  const { selected_terms_uuid } = nonParsedValues;
  // use parsed negotiated terms in brief summary component
  const parsedNegotiatedAgreement = (data && parseRemoteNegotiatedTerms(nonParsedValues)) || {};

  const agreementError = !data?.data?.success && data?.data?.error_message;

  const { data: helloSignData = {}, refetch: refetchSignUrl } = useQuery(
    ['designerPitchHelloSign', pitch_uuid, buyer_pitch_uuid],
    () => getDesignerPitchSignUrl(pitch_uuid, buyer_pitch_uuid),
    {
      enabled: agreement_status === AGREEMENT_STATUS.AWAITING_SIGN,
    }
  );

  const { claim_url, success: helloSignSuccess, message: helloSignMessage } = helloSignData;
  const helloSignErrorMsg = !helloSignSuccess && helloSignData?.data?.error_message;

  useEffect(() => {
    const errorMsg = agreementError || helloSignErrorMsg;
    if (errorMsg) {
      setMessage(errorMsg);
    }
  }, [helloSignErrorMsg, agreementError, setMessage]);

  const { data: termsData, isLoading: isTermsLoading } = useQuery(
    ['designerPitchTerms', selected_terms_uuid],
    () => (selected_terms_uuid ? getSingleTerm(selected_terms_uuid) : {}),
    {
      enabled: true,
    }
  );

  const terms = (termsData?.terms && parseRemoteTermsData(termsData?.terms)) || {};

  const handleAcceptAgreement = useCallback(async () => {
    const res = await acceptDesignerPitchBuyerSingleAgreement(pitch_uuid, buyer_pitch_uuid);
    if (!res.success) {
      return setMessage('There was an error while accepting the agreement. Please try again.');
    }
    // refetch and refresh data
    refetch();
    queryClient.refetchQueries(['designerSinglePitch', pitch_uuid, buyer_pitch_uuid], { type: 'active', exact: true });
    setOpenHasAcceptedAgreement(true);
  }, [buyer_pitch_uuid, pitch_uuid, queryClient, refetch, setMessage]);

  const onHelloSignClick = useCallback(async () => {
    openHelloSign(claim_url, () => {
      refetchSignUrl();
      refetch();
      queryClient.refetchQueries(['designerSingleProduct', pitch_uuid, buyer_pitch_uuid], {
        type: 'active',
        exact: true,
      });
    });
  }, [buyer_pitch_uuid, claim_url, pitch_uuid, queryClient, refetch, refetchSignUrl]);

  const loading = isLoading || isTermsLoading;

  return (
    <Box mt={2}>
      {loading ? (
        <Box>
          <Spinner align="center" />
        </Box>
      ) : (
        <AgreementAccordion
          briefDetails={{
            ...([AGREEMENT_STATUS.IN_REVIEW, AGREEMENT_STATUS.AWAITING_BUYER, AGREEMENT_STATUS.AWAITING_SIGN].includes(
              agreement_status
            )
              ? parsedNegotiatedAgreement
              : {}),
            ...terms,
            currency,
          }}
          agreement_status={agreement_status}
          project_status={project_status}
          userType={userTypes.designer}
          download_url={download_url}
          file={{}}
          onAcceptAgreement={() => setOpenAcceptAgreement(true)}
          onHelloSignClick={claim_url && onHelloSignClick}
          helloSignMessage={helloSignMessage}
        />
      )}
      {openAcceptAgreement && (
        <ModalConfirmAccept
          open={openAcceptAgreement}
          name="agreement"
          handleSubmit={handleAcceptAgreement}
          handleClose={() => setOpenAcceptAgreement(false)}
        />
      )}

      {openHasAcceptedAgreement && (
        <ModalProposalAgreementSigned
          open={openHasAcceptedAgreement}
          handleClose={() => setOpenHasAcceptedAgreement(false)}
          userType={userType}
        />
      )}
    </Box>
  );
};

DesignerPitchesSingleAgreementTab.propTypes = {};

export default DesignerPitchesSingleAgreementTab;
