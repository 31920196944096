import HelloSign from 'hellosign-embedded';

const client = new HelloSign({
  clientId: process.env.REACT_APP_HELLO_SIGN_ID,
});

export const openHelloSign = (claimURL, cb) => {
  client.open(claimURL, {
    testMode: process.env.REACT_APP_TEST_MODE === 'true',
    skipDomainVerification: process.env.REACT_APP_TEST_MODE === 'true',
  });

  client.once('sign', cb);
};
